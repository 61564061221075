import { Component, Input, OnInit } from '@angular/core';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-edit-note',
  templateUrl: './edit-note.component.html',
  styleUrls: ['./edit-note.component.scss']
})
export class EditNoteComponent extends BaseFormDialog1 implements OnInit {

  @Input() carrierBidInfo;
  @Input() carrierBidItem;

    public isError = false;
    public isLoading = false;

    protected formGroupDeclaration: FormGroupDeclaration = {
      note: {
        label: "Note",
        placeHolder: "Please enter note"
      },
    };
    constructor(api: ApiService) {
      super();
      this.api = api;
    }

    get isCreateNew(): boolean {
      return true;
    }

    ngOnInit(): void {
      super.ngOnInit();
      this.createFormInput();
      this.setItemValue("note", this.carrierBidItem?.note)
    }

    onBtnSave() {
      let json: any = this.getFormData_JSON(true);
      this.setEnableFormGroup(false);
      this.startProgress();
      const params = {
        carrierId: this.carrierBidItem.carrierId,
        note: json.note,
      };
      this.api.POST(`${Const.APIURI_CARRIER_BIDS}/${this.carrierBidInfo.id}/edit-note`, params).subscribe(
        (resp) => {
          this.stopProgress();
          this.onUpdateSuccess(resp)
        },
        (err) => {
          this.showErr("There was an error during the update.")
          this.setEnableFormGroup(true);
          this.stopProgress();
          console.error(" error: ", err);
        }
      );
    }
}
