import { Component } from "@angular/core";

import { BaseComponent } from "@abstract/BaseComponent";
import { DialogService } from "@dialogs/dialog.service";
import { Subscription } from "rxjs";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";
import { Utils } from "@services/utils";
import { InputHelper } from "@services/input-helper";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { EditCarrierBidSettings } from "@app/admin/components/carrier-bid/edit-settings";
import { Const } from "@const/Const";

@Component({
  selector: "carrier-sale-warp-offer",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CarrierSaleWarpOffer extends BaseComponent {
  bid: any;
  jobHyperLink;
  displayInfo: any = {};
  private bidDetailSubscription: Subscription;
  onHold: string[] = [];
  public basePrice: any //giá bid mà WARP offer
  
  constructor(private carrierSaleDetailService: CarrierSaleDetailService) {
    super();
  }

  ngOnInit() {
    this.bidDetailSubscription = this.carrierSaleDetailService.bidDetail$.subscribe(bidDetail => {
      this.bid = bidDetail;
      this.onProcessRouteInfo(bidDetail);
    });
  }

  getMoneyValue(value) {
    return '$' + InputHelper._formatMoney(value, 0);
  }

  private onProcessRouteInfo(bidDetail) {
    this.basePrice =  Utils.isNumber(bidDetail?.basePrice) ? InputHelper.formatMoney2(String(bidDetail.basePrice)) : null;
  }

  onBtnEditSettings() {
    DialogService.openFormDialog1(EditCarrierBidSettings, {
      nzComponentParams: {
        model: {
          type: this.bid?.type,
          isAllowPlaceBid: this.bid?.isAllowPlaceBid,
          isShowBasePrice: this.bid?.isShowBasePrice,
          basePrice: this.bid?.basePrice,
          isShowOnBidBoard: this.bid?.isShowOnBidBoard,
          isRequireCarrierAcceptLoadTender: this.bid?.isRequireCarrierAcceptLoadTender,
        },
        isSourceMarketPlace: this.displayInfo.isMarketplace,
        onSave: data => {
          let url = `${Const.APIURI_CARRIER_BIDS}/${this.bid.id}/settings`;
          return this.api.PUT(url, data)
        },
        updateSuccess: resp => {
          this.carrierSaleDetailService.emitGetDataBid();
        }
      },
      nzClassName: 'modal-no-padding',
    })
  }
  

  ngOnDestroy() {
    if (this.bidDetailSubscription) {
      this.bidDetailSubscription.unsubscribe();
    }
  }
 
}