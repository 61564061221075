<div class="top15 bottom15 bid-summany-selection">
  <carrier-sale-route-info></carrier-sale-route-info>
  <div class="more-info-selection">
    <div class="more-info-item width-smaller" style="position: relative;">
      <carrier-sale-bid-setting></carrier-sale-bid-setting>
      <carrier-sale-equipment></carrier-sale-equipment>
    </div>
    <div class="more-info-item width-smaller" style="position: relative;">
      <carrier-sale-warp-offer></carrier-sale-warp-offer>
      <carrier-sale-rate></carrier-sale-rate>
      <carrier-sale-market-rate></carrier-sale-market-rate>
    </div>
    <div class="more-info-item width-smaller" style="position: relative;">
      <carrier-sale-same-lane-load></carrier-sale-same-lane-load>
    </div>
  </div>
</div>