import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { DialogsModule } from '@dialogs/dialogs.module';
import { DetailModule } from '../base/detail.module';
import { FormAddressUSModule } from '../base/form-address-us/module';
import { CarrierBidList } from './list';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { CreateCarrierBidComponent } from './components/create-carrier-bid/create-carrier-bid.component';
import { DetailCarrierBidComponent } from './components/detail-carrier-bid/detail-carrier-bid.component';
import { EditCarrierBidAnswerComponent } from './components/edit-carrier-bid-answer/edit-carrier-bid-answer.component';
import { SharedModule } from "../shared/shared.module";
import { SendMessageComponent } from './components/send-message/send-message.component';
import { EditNoteComponent } from './components/edit-note/edit-note.component';
import { CarrierBidFilterComponent } from './components/carrier-bid-filter/carrier-bid-filter.component';
import { AddCarrier } from './components/detail-carrier-bid/add-carrier';
import { AddCarrierPool } from './components/detail-carrier-bid/add-carrier-pool';
import { FormInputCostModule } from '../base/form-input-cost/module';
import { CommLogModule } from '../components/comm-log/index.module';
import { UiCommonModule } from '../components/common/module';
import { AddCarrierPoolByCoverageModule } from './components/detail-carrier-bid/add-carrier-pool-by-coverage/module';
import { SelectCarrierByServiceAreaModule } from './components/detail-carrier-bid/add-carrier-by-coverage/module';
import { DatPostComponent } from './components/detail-carrier-bid/dat-post/dat-post.component';
import { DatPostDetailComponent } from './components/detail-carrier-bid/dat-post/dat-post-detail.component';
import { AssignCarrier } from "./components/assign-carrier";
import { CarrierPoolSelectList } from "./components/detail-carrier-bid/select-list-carrier-pools";
import { CarrierPoolFilter } from "./components/detail-carrier-bid/select-list-carrier-pools/filter";
import { DATTruckSearch } from './components/detail-carrier-bid/truck-search';
import { EditCarrierLookups } from './components/detail-carrier-bid/edit-carrier-lookups-dialog';
import { ModuleRating } from '../components/rating/module';
import { EditOptionalEquipment } from '../components/carrier-bid/edit-optional-equipment';
import { EditCarrierBidSettings } from '../components/carrier-bid/edit-settings';
import { CalendarSameLane } from '../components/carrier-bid/calendar-same-lane';
import { LoadingModule } from '../components/loading-bar/module';
import { CarrierSalesResponseModule } from '../report/carrier-sales-response/module';
import { EditCounterComponent } from './components/edit-counter/edit-counter.component';
import { CounterForCarrierComponent } from './components/counter-for-carrier/counter-for-carrier.component';
import { ModuleJobStopTimeline } from '../components/carrier-bid/job-stop-timeline/module';
import { CalendarSameLaneModule } from '../components/carrier-bid/calendar-same-lane/module';
import { ViewPreviousRouteModule } from '../components/carrier-bid/view-previous-route/module';
import { ThirdPartyPostModule } from '../third-party-post/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzTagModule,
    NzSwitchModule,
    NzDropDownModule,
    NzTableModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    CommLogModule,
    NzInputModule,
    NzFormModule,
    NzPaginationModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    NzToolTipModule,
    NzTypographyModule,
    SearchBoxModule,
    DialogsModule,
    DetailModule,
    FormAddressUSModule,
    SelectBySearchingModule,
    SharedModule,
    FormInputCostModule,
    UiCommonModule,
    ModuleJobStopTimeline,
    AddCarrierPoolByCoverageModule,
    ModuleRating,
    SelectCarrierByServiceAreaModule,
    LoadingModule,
    CarrierSalesResponseModule,
    CalendarSameLaneModule,
    ViewPreviousRouteModule,
    ThirdPartyPostModule,
  ],
  declarations: [
    CarrierBidList,
    CreateCarrierBidComponent,
    DetailCarrierBidComponent,
    EditCarrierBidAnswerComponent,
    SendMessageComponent,
    EditNoteComponent,
    CarrierBidFilterComponent,
    AddCarrier,
    AddCarrierPool,
    AssignCarrier,
    DatPostComponent,
    DatPostDetailComponent,
    CarrierPoolSelectList,
    CarrierPoolFilter,
    DATTruckSearch,
    EditCarrierLookups,
    EditOptionalEquipment,
    EditCarrierBidSettings,
    EditCounterComponent,
    CounterForCarrierComponent,
  ],
  exports: [

  ],
  providers: [
  ]
})
export class CarrierBidsModule {}
