import { Component, Input, OnInit, Output } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { BizUtil } from "@services/biz";
import { ThirdPartyPost } from "@wearewarp/types/data-model/types/third-party";

@Component({
  selector: "external-posting-post-detail",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class ExternalPostingDetailDialog extends BaseFormDialog1 implements OnInit {
  isLoading
  isError = false;
  @Input() carrierBidId: string;
  @Input() postId: string = null;
  @Input() reloadData: () => void = () => {};
  data: ThirdPartyPost;

  jobHyperLink: any;

  ngOnInit(): void {
    super.ngOnInit();
    if (this.postId) {
      this.getDetail();
    } else {
      this.getInit();
    }
  }

  get isCreateNew() {
    return this.postId === null;
  }

  getInit() {
    this.isLoading = true;
    this.api.GET(`${Const.APIURI_THIRD_PARTY_POST}/carrier-bid/${this.carrierBidId}/init`).subscribe(
      (response) => {
        this.data = response.data || {};
        this.isLoading = false;
      },
      (error) => {
        this.showErr(error);
      }
    );
  }

  getDetail() {
    this.isLoading = true;
    this.api.GET(`${Const.APIURI_THIRD_PARTY_POST}/${this.postId}`).subscribe(
      (response) => {
        this.data = response.data || {};
        this.isLoading = false;
      },
      (error) => {
        this.getInit();
      }
    );
  }

  onBack() {
    this.closeDialog();
  }

  onUpdateSuccess() {
    this.closeDialog();
    this.reloadData();
  }

}
