<div class="flex-space-between">
  <div class="text-header">Bid settings</div>
  <div style="margin-right: 10px;">
    <a (click)="onBtnEditSettings()">
      <i nz-icon nzType="setting" nzTheme="outline" style="margin-right: 4px;"></i>Settings
    </a>
  </div>
</div>
<div class="line-item">
  <div >
    <ng-container>{{ displayInfo.bidModeType }}</ng-container>
    <ng-container *ngIf="bid?.isAllowPlaceBid"> & Allow the carrier place bid</ng-container>
  </div>
</div>