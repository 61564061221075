import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import mapboxgl from 'mapbox-gl';
import { BaseDialog } from '@dialogs/base-dlg';
import { MasterData } from '@services/master.data';
import { Const } from '@const/Const';
import { MapBidService } from '@app/admin/carrier-sale-detail/carrier-sale-map.service';

interface SelectedPool {
  id: string,
  name: string,
  featureId: number,
  numberOfCarrier: number
}

@Component({
  selector: '[carrier-sale-add-carrier-pool-by-area]',
  templateUrl: './index.html',
  styleUrls: ['./index.less', '../../../../../app.scss', '../../../../../dialogs/dialogs.scss']
})

export class AddCarrierPoolByArea extends BaseDialog {

  _model = null;
  @Input() set model(value) {
    this._model = value
    this.mapService = new MapBidService()
    this.mapService.setBid(value)
  }
  mapService: MapBidService
  get model() { return this._model }
  map = null;
  popup = null;
  draw = null;
  isSelectAll: boolean = false;
  routeData;

  defaultLayerId = {
    counties: 'counties',
    states: 'states',
    addSelected: 'addSelected',
  };
  recommendedPoolAreas = []
  selectedPools: SelectedPool[] = []

  public onSave: (data) => void = () => { };
  public onDelete: () => void = () => { };
  calculateLoading = false
  @ViewChild('mapElm') mapElm: ElementRef<HTMLElement>;

  constructor() {
    super();
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.getRouteData()
    this.getRecommendPools()
    setTimeout(() => {
      this.setupMap();
    }, 500);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public onBtnCancel() {
    this.closeDialog();
  }

  onDeletedSelectedItem(value) {
    this.selectedPools = this.selectedPools.filter(item => item.id != value.id);
    const featureIds = this.getFeatureIdsByCarrierId(value.id)
    this.processHightlight(featureIds, false);
  }

  getFeatureIdsByCarrierId(poolId) {
    let list = this.recommendedPoolAreas.filter(item => item.poolId === poolId)
    return list.map(item => item.featureId)
  }

  private processSelectedAreaItem() {
    const val = [];
    this.recommendedPoolAreas.forEach(item => {
      let f = JSON.parse(JSON.stringify(item.area.data ?? {}))
      val.push(f);
    })
    return val;
  }

  private initAreas() {
    const data = {
      type: 'FeatureCollection',
      features: this.processSelectedAreaItem(),
    };
    
    this.map.addSource(this.defaultLayerId.addSelected, { type: 'geojson', data });
    this.map.addLayer({
      'id': this.defaultLayerId.addSelected,
      'type': 'fill',
      'source': this.defaultLayerId.addSelected,
      'layout': {},
      'paint': {
        'fill-color': [
          'case',
          ['boolean', ['feature-state', 'click'], false],
          'rgba(218, 153, 22, 0.06)',
          'rgba(24, 2, 208, 0.06)',
        ],
        "fill-opacity": 0.06
      }
    });
    this.map.addLayer({
      'id': 'addSelected-border',
      'type': 'line',
      'source': this.defaultLayerId.addSelected,
      'layout': {},
      'paint': {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'click'], false],
          'rgba(218, 153, 22, 1)',
          '#1802D0',
        ],
        'line-width': 3
      }
    });
    this.map.on('click', this.defaultLayerId.addSelected, this.onSelectArea);
  }

  onSelectHightlight(item) { }

  processHightlight(featureIds, click = false) {
    for (let id of featureIds) {
      this.map.setFeatureState(
        { source: this.defaultLayerId.addSelected, id },
        { click: click }
      );
    }
  }

  onSelectArea = (e) => {
    let features = e.features ?? []
    let featureIds = features?.map(item => item?.properties.id).filter(item => item)
    this.addCarriersByFeatureId(featureIds)
    this.processHightlight(featureIds, true);
  }

  addCarriersByFeatureId(featureIds = []) {
    for (let id of featureIds) {
      let exist = this.recommendedPoolAreas.find(item => item.id == String(id))
      if (exist) {
        //kiểm tra xem đã add chưa
        let added = this.selectedPools.find(item => item.id === exist?.pool?.id)
        if (!added) {
          this.selectedPools.push({
            id: exist?.pool?.id,
            name: exist?.pool?.name,
            featureId: id,
            numberOfCarrier: exist?.pool?.subjectIds?.length
          })
        }
      }
    }
  }


  private setupMap() {
    const mapBoxStyleLight = 'mapbox://styles/mapbox/streets-v11';
    mapboxgl.accessToken = MasterData.mapboxToken;

    this.map = new mapboxgl.Map({
      container: 'dispatch-map',
      style: mapBoxStyleLight,
      center: this.mapService.center,
      zoom: 6
    });
    this.mapService.setMap(this.map)
    let bounds = new mapboxgl.LngLatBounds(this.mapService.bounds);
    this.map.fitBounds(bounds, { padding: { top: 100, bottom: 100, right: 300, left: 300 }, maxZoom: 7 });
    this.map.on('load', () => {
      this.initAreas();
      this.mapService.addStops();
      this.reloadPoolAreas()
    });
    this.displayWhenOver()
  }

  getRecommendPools() {
    let vehicles = this.model?.vehicles ?? []
    let equipments = vehicles.map(i => i.code)
    this.api.POST(Const.APIV2(`${Const.APIURI_POOLS}/recommend-pools`), { 
      areas: this.mapService.bidAreas(),
      equipments
     }).subscribe(
      (response) => {
        this.recommendedPoolAreas = response?.data?.list_data ?? []
        this.reloadPoolAreas()
      },
      (err) => {
        this.showErr(err);
        this.calculateLoading = false
      }
    );
  }

  reloadPoolAreas(){
    if(!this.map) return;
    if(!this.map.getSource(this.defaultLayerId.addSelected)) return;
    const data = {
      type: 'FeatureCollection',
      features: this.processSelectedAreaItem(),
    };
    this.map.getSource(this.defaultLayerId.addSelected).setData(data);
    this.calculateLoading = false
  }

  onBtnContinue() {
    let carrierIds: string[] = []
    for (let pool of this.selectedPools) {
      let exist = this.recommendedPoolAreas.find(item => item.pool?.id === pool.id);
      if (exist?.pool?.subjectIds) {
        carrierIds = carrierIds.concat(exist?.pool?.subjectIds)
      }
    }

    this.onSave(carrierIds);
    this.closeDialog();
  }

  getRouteData() {
    if (!this.model?.jobId) return
    this.api.GET(`${Const.APIURI_JOBS}/${this.model?.jobId}/route-data`).subscribe(
      (resp) => {
        this.routeData = resp?.data?.routeData || null;
        setTimeout(() => {
          this.mapService.setupRouteOnMap(this.routeData)
        }, 2000)
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  displayWhenOver() {
    let popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });
    this.map.on('mousemove', this.defaultLayerId.addSelected, (e) => {
      this.map.getCanvas().style.cursor = 'pointer';
      let features = e.features ?? []
      function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
      }
      
      let featureIds = features?.map(item => item?.properties?.id).filter(onlyUnique)
      let html = ''
      let i = 1;
      for(let id of featureIds){
        let data = this.recommendedPoolAreas.find(item => item.id == id)
        if(data){
          html += `<h5>${i}. ${data?.pool?.name} (${data?.area?.name}) </h5></br>`
          i++;
        }
      }
      popup
        .setLngLat(e.lngLat)
        .setHTML(html)
        .addTo(this.map);

    });
    this.map.on('mouseleave', this.defaultLayerId.addSelected, () => {
      this.map.getCanvas().style.cursor = '';
      popup.remove();
    });
  }

  onSelectAll() {
    let featureIds = this.recommendedPoolAreas?.map(item => Number(item.featureId)).filter(item => item)
    this.addCarriersByFeatureId(featureIds)
    this.processHightlight(featureIds, true);
  }

  onReset() {
    let featureIds = this.recommendedPoolAreas?.map(item => Number(item.featureId)).filter(item => item)
    this.selectedPools = []
    this.processHightlight(featureIds, false)
  }
}