import { Component } from "@angular/core";

import { BaseComponent } from "@abstract/BaseComponent";
import { DialogService } from "@dialogs/dialog.service";
import { Subscription } from "rxjs";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";
import { Utils } from "@services/utils";
import { InputHelper } from "@services/input-helper";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { EditCarrierBidSettings } from "@app/admin/components/carrier-bid/edit-settings";
import { Const } from "@const/Const";

@Component({
  selector: "carrier-sale-outreach",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CarrierSaleOutreach extends BaseComponent {
  bid: any;
  jobHyperLink;
  displayInfo: any = {};
  private bidDetailSubscription: Subscription;
  onHold: string[] = []

  public tabSelected: number = 0;
  private tabIndexs = {
    'matched-carriers': 0,
    'external-posting': 1,
  }
  
  constructor(private carrierSaleDetailService: CarrierSaleDetailService) {
    super();
  }

  ngOnInit() {
    this.bidDetailSubscription = this.carrierSaleDetailService.bidDetail$.subscribe(bidDetail => {
      this.bid = bidDetail;
    });
  }

  onTabSelectedIndexChange() {
  }

  ngOnDestroy() {
    if (this.bidDetailSubscription) {
      this.bidDetailSubscription.unsubscribe();
    }
  }

  get isMatchedCarriersTab() {
    return this.tabSelected === this.tabIndexs['matched-carriers'];
  }

  get isExternalPostingTab() {
    return this.tabSelected === this.tabIndexs['external-posting'];
  }
 
}