<div class="section-result">
  <div class="text-header">Results</div>
  <ng-container *ngIf="acceptedCarrier">
    <carrier-sale-accept-price 
      [bid]="bid"
    >
    </carrier-sale-accept-price>
  </ng-container>
  <carrier-sale-carrier-result
    [bid]="bid"
    (onReload)="onReloadData()"
  >
  </carrier-sale-carrier-result>
</div>

