<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<div *ngIf="!isLoading && !isError">
  <div carrier-sale-bid-info></div>
</div>

<div *ngIf="!isLoading && !isError">
  <carrier-sale-outreach></carrier-sale-outreach>
</div>
<div *ngIf="!isLoading && !isError">
  <div class="carrier-selection">
    <carrier-sale-carries></carrier-sale-carries>
  </div>
</div>