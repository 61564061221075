import { Component, Input, ViewChild } from "@angular/core";
import { Const } from "@app/const/Const";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "@abstract/BaseComponent";
import { PaginationData } from "@app/model/PaginationData";
import { NzTableComponent } from "ng-zorro-antd/table";
import { Utils } from "@services/utils";

@Component({
  selector: "carrier-sale-pools-select-list",
  templateUrl: "./index.html",
  styleUrls: ["../../../../list.scss", "./style.scss"],
})
export class CarrierSalePoolList extends BaseComponent {
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  @Input() model: any;
  @ViewChild('nzTable', { static: false }) nzTable: NzTableComponent<any>;

  paginationData: PaginationData<any> = new PaginationData<any>();
  searchKeyword = '';
  pageCount: number = 0;
  pageIndex = 1; // for those who use nz-pagination without nz-table
  isLoading = false;
  private _params: any = {}; // store data pagination
  private subApi;

  private setQueryParams(key: string, value: any) {
    this._params[key] = value
  }

  get currentPageSize(): number { return Const.PAGINATION_LIMIT }
  get limit(): number { return this.currentPageSize; }
  get currentPage(): number { return Utils.toNumber((this._params || {}).page, 1); }
  get skip(): number {
    return this.limit < 0 ? 0 : (this.currentPage - 1) * this.limit;
  }
  get searchFromQuery(): string { return (this._params || {}).search || '' }
  get listData(): any[] { return this.paginationData ? this.paginationData.list_data : [] }
  get totalCount(): number { return this.paginationData ? this.paginationData.total : 0 }

  checkedAll: boolean = false;
  indeterminate: boolean = false;
  _mapselectedItems: any = {};
  get selectedItems() { return Object.values(this._mapselectedItems || {}) }

  ngOnInit(): void {
    this.refreshData();
  }

  refreshData() {
    this.getData();
  }

  get defaultFilter() {
    return { subjectType: 'carrier' }
  }

  private getApiUrl(): string {
    return `${Const.APIV2(Const.APIURI_POOLS)}`
  }

  private prepareParamGetList() {
    let params: any = {};
    params.skip = this.skip;
    params.limit = this.limit;
    if (this._params.sort) {
      params.sort = this._params.sort;
    }
    if (this._params.search) {
      params.search = this._params.search;
    }
    let filters: any = { ...this.defaultFilter };
    if (this._params.filter) {
      filters = { ...filters, ...this._params.filter };
    }
    params.filter = JSON.stringify(filters);
    return params;
  }

  private getData() {
    let apiUrl = this.getApiUrl();
    let params = this.prepareParamGetList();
    let qs = new URLSearchParams(params).toString();
    if (apiUrl.indexOf('?') === -1) {
      apiUrl += '?';
    } else {
      apiUrl += '&';
    }
    apiUrl += qs;
    this.getDataByUrl(this.api.buildUrl(apiUrl));
  }

  private getDataByUrl(url: string) {
    this.isLoading = true;
    this.subApi?.unsubscribe();
    this.subApi = this.api.GET(url).subscribe(
      resp => {
        console.log('get list data done: ', resp);
        this.getDataDone(resp);
        this.isLoading = false
      }, err => {
        this.showErr(err);
        this.isLoading = false
      }
    );
  }

  private getDataDone(resp) {
    this.paginationData.setData(resp.data);
    if (this.limit > 0) {
      this.pageCount = Math.ceil(this.totalCount / this.limit);
    } else {
      this.pageCount = 1;
    }
    let page = Utils.toNumber(this.currentPage, 1);
    if (this.nzTable && this.nzTable.nzPageIndex != page) {
      this.nzTable.nzPageIndex = page;
    }
    this.onGetDataSucceeded(resp);
  }

  private onGetDataSucceeded(resp: any) {
    this.refreshCheckedAll()
  }

  onDataListPageChanged(page: any) {
    this.setQueryParams("page", page);
    this.refreshData()
  }

  onSortOrderChange(event: any) {
    let sort: any = null;
    switch (event.value) {
      case 'ascend':
        sort = `${event.key},1`;
        break;
      case 'descend':
        sort = `${event.key},-1`;
        break;
      default:
        break;
    }
    this.setQueryParams('sort', sort)
    this.refreshData()
  }

  doSearch(data: any) {
    const { searchKeyword, ...filter } = data || {};
    this.setQueryParams('page', 1)
    this.setQueryParams('search', searchKeyword)
    this.setQueryParams('filter', filter)
    this.refreshData()
  }

  getEquipments(item) {
    let vehicleObjTypes = item?.vehicleObjTypes ?? []
    let result = vehicleObjTypes.map(v => v.name)
    result = result.join(', ')
    return result;
  }

  setOfCheckedId = new Set<string>();
  updateCheckedSet(item: any, checked: boolean): void {
    const { id } = item;
    if (checked) {
      this.setOfCheckedId.add(id);
      this._mapselectedItems[id] = item;
    } else {
      this.setOfCheckedId.delete(id);
      delete this._mapselectedItems[id]
    }
  }

  refreshCheckedAll(): void {
    const listOfEnabledData = this.listData.filter(({ disabled }) => !disabled);
    this.checkedAll = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checkedAll;
  }

  onItemChecked(item: any, checked: boolean): void {
    this.updateCheckedSet(item, checked);
    this.refreshCheckedAll();
  }

  onAllChecked(checked: boolean): void {
    this.listData
      .filter(({ disabled }) => !disabled)
      .forEach((item) => this.updateCheckedSet(item, checked));
    this.refreshCheckedAll();
  }

  clearAllSelected() {
    this._mapselectedItems = {};
    this.setOfCheckedId.clear();
    this.refreshCheckedAll()
  }

  getDetailLocation(location: any) {
    location = location || {}
    let arr: any = [];
    ["state", "city", "zipcode"].map(key => {
      if (location[key]) arr.push(location[key])
    });
    return arr.join(", ")
  }

}
