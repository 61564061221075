<div class="dashboard-child-container no-padding list">
  <div>
    <nz-row >
      <nz-col [nzMd]="24">
        <div class="list-container">
          <div class="list-header">
            <div class="title">
              <p>Share this load to other marketplaces to reach more carriers.</p>
            </div>
            <ng-container *ngIf="shouldShowBtnPost">
              <button nz-button nzType="primary" (click)="onBtnCreateNewPost()">Create Post</button>
            </ng-container>
          </div>

          <div class="list-body top20">
            <nz-table #nzTable nzBordered="true" [nzData]="listPost" [nzLoading]="isLoading" [nzHideOnSinglePage]="true"
            [nzLoadingIndicator]="tplLoading" [nzNoResult]="tplNoData" [nzSize]="'default'">
              <ng-template #tplNoData>
                <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
              </ng-template>
              <ng-template #tplLoading>
                <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
              </ng-template>
              <thead>
                <tr>
                  <th nzLeft> Post</th>
                  <th nzLeft>Pickup Address</th>
                  <th nzLeft>Delivery Address</th>
                  <th nzLeft>Load</th>
                  <th nzLeft> Post Status</th>
                  <th nzLeft nzWidth="140px">Action</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let post of nzTable.data; let i = index">
                  <tr>
                    <td>
                      <div class="post-party">
                        <div>{{ i+1 }}</div>
                        <div>
                          <img *ngIf="!!getPartyLogo(post)" alt="party-logo" class="logo" [src]="getPartyLogo(post)">
                          <div *ngIf="!getPartyLogo(post)">{{ post.party }}</div>
                        </div>
                      </div>
                      <div class="time-info">
                        <div>Updated: {{ getPostUpdatedTime(post) }}
                          <span *ngIf="post?.updateBy">- by {{ getFullName(post?.updateBy) }}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="address">{{ getRouteOrigin(post)}}</div>
                        <div class="time-info">{{ getPickupTime(post) }}</div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="address">{{ getRouteDestination(post) }}</div>
                        <div class="time-info">{{ getDropoffTime(post) }}</div>
                      </div>
                    </td>
                    <td>
                      {{getPostLoadInfo(post)}}
                    </td>
                    <td>
                      <nz-tag [nzColor]="getPostStatusColor(post)" [nz-tooltip]="getErrorMessage(post)">{{getPostStatus(post)}}</nz-tag>
                    </td>
                    <td>
                      <div class="post-group-action">
                        <button nz-button nzType="text" [disabled]="isClosedPost(post)" nzDanger (click)="onBtnDeletePost(post, tplTitle)" nz-tooltip nzTooltipTitle="Delete post"><i nz-icon nzType="delete" nzTheme="outline"></i></button>
                        <button nz-button nzType="text" [disabled]="isClosedPost(post) || isCreateError(post)" (click)="onBtnEditPost(post)" nz-tooltip nzTooltipTitle="Edit post"><i nz-icon nzType="edit" nzTheme="outline"></i></button>
                        <button nz-button nzType="text" [disabled]="isClosedPost(post) || isCreateError(post)" (click)="onBtnRefreshPost(post, i)" [nzLoading]="isRefreshingPostId(post?.id)" nz-tooltip nzTooltipTitle="Refresh this post"><i nz-icon nzType="reload" nzTheme="outline"></i></button>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </nz-table>
          </div>
        </div>
      </nz-col>
    </nz-row>
  </div>
</div>

<ng-template #tplTitle>
  <i nz-icon nzType="exclamation-circle" nzTheme="outline" class="danger"></i>
  <span class="left10">Delete post?</span>
</ng-template>
