import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
@Component({
  selector: '[detail-bid-ware-item]',
  templateUrl: 'index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class DetailBidWareItem extends BaseComponent {
  @Input() index: number = 0;
  @Input() time: string;
  @Input() status: string = '';
  @Input() isStatusBeforeItem: string = '';
  @Input() isLine: boolean = true;
  @Input() carrierCount: number = 0;
  @Input() sendEmailCount: number = 0;
  @Input() sendSmsCount: number = 0;
  @Input() waveListCurrentPage: number = 0;
  @Input() waveListCount: number = 0;
  @Input() createdBy: string = '';
  @Input() summary: { notSent?: number, sent?: number, canceled?: number } = {};


  getStatusTxt(): string {
    const statuses = {
      in_progress: 'In Progress',
      complete: 'Sent',
      pending: 'Queued',
      canceled: 'Canceled'
    }
    return statuses[this.status] || 'Queued';
  }

  get content(): string {
    let text: string = '';
    if(this.summary.notSent) {
      text += `Not Sent: ${this.summary.notSent} <br/>`;
    }
    if(this.summary.sent) {
      text += `Sent: ${this.summary.sent} <br/>`;
    }
    if(this.summary.canceled) {
      text += `Canceled: ${this.summary.canceled} <br/>`;
    }
    return text;
  }
}
