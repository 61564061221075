<ng-template [nzModalTitle]>
    <div class="flex-space-between">
      <div>{{ txtTitle }}</div>
      <div>
        <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
      </div>
    </div>
</ng-template>
<div class="bottom5">
    <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    <div *ngIf="!isLoading">
        <div *ngIf="data" class="bottom20" [ngClass]="{ 'red' : data.numOfCarriers >=100 }">
            <div class="line">
                {{ data.numOfCarriers }} {{ data.numOfCarriers > 1 ? 'Carriers' : 'Carrier' }} Selected
            </div>
            <div class="line">
                {{ data.numOfSms }} SMS sending 
                <span *ngIf="data.costSms">(Cost ~{{ data.costSms }})</span>
            </div>
            <div class="line">
                {{ data.numOfEmail }} {{ data.numOfEmail > 1 ? 'Emails' : 'Email' }} sending
                <span *ngIf="data.numOfEmail > 100">(! Spam warning)</span>
            </div>
            <div *ngIf="type !== 'sendRemind'" class="line">
                Sending in 1 minute
            </div>
        </div>

        <div class="medium f15 bottom20">
            <div *ngIf="type === 'sendRemind'">
                Bid invitation would be sent all carriers no responsed. Are you sure you want to update?
            </div>
            <div *ngIf="type !== 'sendRemind'">
                Are you sure you want to send ASAP?
            </div>
            
        </div>

        <div class="form-footer-send-asap">
            <div form-footer [onProgress]="onProgress" 
                [nzIconCancel]="null" [nzIconOK]="null"
                [canClickOK]="!onProgress && !isLoading"
                [canClickCancel]="!onProgress" labelOK="Yes"
                (onOK)="onBtnConfirm()" (onCancel)="closeDialog()">
            </div>
        </div>
    </div>
</div>