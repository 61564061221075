import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { AddCarrierByServiceArea } from '.';
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzButtonModule,
    NzGridModule,
    NzCheckboxModule,
    NzIconModule,
    NzPopoverModule,
  ],
  declarations: [
    AddCarrierByServiceArea,
  ],
  exports: [
    AddCarrierByServiceArea
  ],
  providers: [
  ]
})
export class AddCarrierByServiceAreaModule {}