import { Component, Input } from "@angular/core";
import { BaseDialog } from "@dialogs/base-dlg";
@Component({
  selector: '[detail-bid-outreach-analytics]',
  templateUrl: 'index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class DetailBidOutreachAnalytics extends BaseDialog {
  @Input() carrierBidId;
}
