import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { InputHelper } from "@services/input-helper";

@Component({
  selector: 'app-edit-carrier-bid-answer',
  templateUrl: './edit-carrier-bid-answer.component.html',
  styleUrls: ['./edit-carrier-bid-answer.component.scss']
})
export class EditCarrierBidAnswerComponent extends BaseFormDialog1 implements OnInit {
    @Input() carrierBidInfo;
    @Input() carrierBidItem;
    @Input() cusSuccessMes: string = '';

    public isError = false;
    public isLoading = false;

    protected formGroupDeclaration: FormGroupDeclaration = {
      answer: {
        label: "Answer",
        placeHolder: "Please choose anwser",
        required: true,
      },
      price: {
        label: "Price",
        placeHolder: "Enter price (if accepted)",
        getValue: InputHelper.getValueMoney, formatValue: InputHelper.formatMoney
      }
    };
    constructor(api: ApiService) {
      super();
      this.api = api;
    }

    get isCreateNew(): boolean {
      return true;
    }

    ngOnInit(): void {
      super.ngOnInit();
      this.createFormInput();
      this.setItemValue("answer", this.carrierBidItem.state+"")
      this.setItemValue("price", this.carrierBidItem.price)
    }

    onAnswerChange(value){
      if(value == 1){
        this.setItemValue("price", "")
        this.getItemByKey("price").disable()
      }
      else if(value == 2){
        const baseRate = this.carrierBidItem?.basePrice && this.carrierBidInfo?.basePrice > this.carrierBidItem?.basePrice ? this.carrierBidItem?.basePrice : this.carrierBidInfo?.basePrice;
        let price = baseRate;
        this.setItemValue("price", price)
        this.getItemByKey("price").disable()
      }
      else
      {
        this.getItemByKey("price").enable()
      }
    }
    
    get isShowAcceptWithOffer(){
      if(this.carrierBidItem?.basePrice && this.carrierBidInfo?.basePrice > this.carrierBidItem?.basePrice) return this.carrierBidItem?.basePrice;
      return this.carrierBidInfo?.basePrice;
    }

    onUpdateSuccess(resp) {
      if (this.cusSuccessMes) {
        this.notification.create('success', 'Response Received', this.cusSuccessMes);
      } else {
        super.onUpdateSuccess(resp);
      }
      this.updateSuccess(resp);
      if (this.closeOnSuccess) {
        this.closeDialog();
      }
    }

    onBtnSave() {
      let json: any = this.getFormData_JSON(true);
      if(json.answer > 2) json.answer = 2; //nếu chọn accept with base price thì cũng đưa về là accept.
      if(!json.price && json.answer == 2){
        return this.showInfo('The price field is required when agreeing to the request.')
      }
      this.setEnableFormGroup(false);
      this.startProgress();
      const params = {
        token: this.carrierBidItem.token,
        price: json.price || 0,
        state: json.answer,
      };
      this.api.POST(`${Const.APIURI_CARRIER_BIDS}/update_bid_info`, params).subscribe(
        (resp) => {
          this.stopProgress();
          this.onUpdateSuccess(resp)
        },
        (err) => {
          this.showErr(err);
          this.setEnableFormGroup(true);
          this.stopProgress();
          console.error(" error: ", err);
        }
      );
    }

    onInputChanged(event, key) {
      switch (key) {
        case 'price': return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true);
      }
    }
  }
