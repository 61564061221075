import { Component } from "@angular/core";
import { BaseList } from "@app/admin/base/list";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { DateUtil } from "@services/date-utils";
import { TimeWindow } from "@wearewarp/types/data-model";
import { VehicleTypeService } from "@services/vehicle.service";
import { BaseComponent } from "@abstract/BaseComponent";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";
import { Subscription } from "rxjs";
import { DialogService } from "@dialogs/dialog.service";
import { ExternalPostingDetailDialog } from "./create-post";
import { ThirdPartyPost } from "@wearewarp/types/data-model/types/third-party";

@Component({
  selector: 'list-external-posting',
  templateUrl: './list.html',
  styleUrls: ['./list.scss']
})

export class ListExternalPosting extends BaseComponent {
  isLoading: boolean = false;
  isPostRefreshing: boolean = false;
  refreshPostId: string = "";
  carrierBidId: string;
  bid: any;
  job: any;
  listPost: any = [];
  equipmentType = [];
  displayInfos: any = [];
  displayKeys = [
    { key: 'postTitle', label: "" },
    { key: 'load', label: "Load" },
    { key: 'comments', label: "Comments" }
  ]

  vehicleTypeService: VehicleTypeService;
  private bidDetailSubscription: Subscription;
  shouldShowBtnPost = true;

  constructor(activatedRoute: ActivatedRoute, api: ApiService, private carrierSaleDetailService: CarrierSaleDetailService) {
    super();
    this.activatedRoute = activatedRoute;
    this.api = api;
    this.carrierBidId = this.getRouteParam(this.activatedRoute, "id");
    this.vehicleTypeService = new VehicleTypeService(this.api);
  }

  ngOnInit() {
    super.ngOnInit();
    this.bidDetailSubscription = this.carrierSaleDetailService.bidDetail$.subscribe(bidDetail => {
      this.bid = bidDetail;
      this.job = bidDetail?.job;
    });
    this.getPosts();
    this.getEquipment();
  }

  ngOnDestroy(): void {
    if (this.bidDetailSubscription) {
      this.bidDetailSubscription.unsubscribe();
    }
  }

  get routeCode() {
    return this.job?.code || '';
  }

  onGetDataSucceeded(resp) {
    this.listPost = resp?.data?.list_data || [];
  }

  buildDisplayInfo() {
    if (!this.listPost) return;
    this.displayInfos = [];
    for (let post of this.listPost) {
      let displayPostInfo = {
        ...post,
        load: this.getPostLoadInfo(post),
        comments: this.getPostComments(post),
        commodity: this.getPostCommodity(post),
        referenceId: this.getPostReference(post),
        pickupAddr: this.getRouteOrigin(post),
        dropoffAddr: this.getRouteDestination(post),
        pickupTime: this.getPickupTime(post),
        dropoffTime: this.getDropoffTime(post),
      }
      this.displayInfos.push(displayPostInfo);
    }
  }

  getEquipment() {
    this.vehicleTypeService.listAll(
      (data) => {
        const types = data.filter((it) => it.active !== false);
        const allTypes = {};
        for (let t of types) {
          allTypes[t.code] = t;
        }
        this.equipmentType = data
          .filter((it) => it.active !== false)
          .map((t) =>
            Object.assign(
              {},
              {
                label: t.name,
                groupLabel: allTypes[t.parent]?.name,
                value: t.code,
                disabled: !t.selectable,
              }
            )
          );
        this.buildDisplayInfo();
      },
      () => {}
    );
  }

  getPosts() {
    const url = `${Const.APIURI_THIRD_PARTY_POST}/carrier-bid/${this.carrierBidId}`;
    this.isLoading = true;
    this.api.GET(`${url}`).subscribe(
      (response) => {
        this.isLoading = false;
        this.listPost = response?.data?.list_data || [];
        this.buildDisplayInfo();

        // this.bid = response.data;
        // this.job = this.bid?.job;
      },
      (error) => {
        this.isLoading = false;
        this.showErr(error);
      }
    );
  }


  onBtnCreateNewPost() {
    this.openExternalPostingDetailDialog(null);
  }

  onBtnEditPost(post) {
    this.openExternalPostingDetailDialog(post.id);
  }

  private openExternalPostingDetailDialog(postId) {
    DialogService.openFormDialog1(ExternalPostingDetailDialog, {
      nzComponentParams: {
        carrierBidId: this.carrierBidId,
        postId: postId,
        closeOnSuccess: true,
        reloadData: () => {
          this.getPosts()
        },
      },
      nzWidth: '1200px',
      nzClassName: "modal",
      nzMaskClosable: false,
    })
  }

  getPostItemLabel(key) {
    switch (key) {
      case 'load':
        return 'Load';
      case 'comments':
        return 'Comments';
      case 'commodity':
        return 'Commodity';
      case 'referenceId':
        return 'Reference Id';
    }
  }

  getPostLoadInfo(post: ThirdPartyPost) {
    let equipment = this.equipmentType.filter(it => it.value === post?.equipment?.code)?.[0];
    let loadType = post?.loadType;
    let lengthFeet = `${post?.dimension.length || 0} ft`;
    let weight = `${post?.dimension.weight || 0} lbs`;
    return `${loadType}, ${equipment?.label} - ${equipment?.value}, ${lengthFeet}, ${weight}`;
  }
  getPostComments(post) {
    return post?.comments?.[0] || '';
  }
  getPostAdditionalComments(post) {
    return post?.comments?.[1] || '';
  }
  getPostCommodity(post) {
    return post?.commodity || '';
  }
  getPostReference(post) {
    return post?.referenceId || '';
  }
  getRouteOrigin(post) {
    return post?.origin ? [post?.origin?.city, post?.origin?.state].join(", ") : '';
  }
  getRouteDestination(post) {
    return post?.destination ? [post?.destination?.city, post?.destination?.state].join(", ") : '';
  }
  getPickupTime(post){
    let timeWindow: TimeWindow = {
      from: post?.pickDt?.fromDate,
      to: post?.pickDt?.toDate,
    }
    return this.getDisplayTimeWindow(timeWindow, post?.pickDt?.timezone);
  }

  getDropoffTime(post) {
    let timeWindow: TimeWindow = {
      from: post?.dropDt?.fromDate,
      to: post?.dropDt?.toDate,
    }
    return this.getDisplayTimeWindow(timeWindow, post?.dropDt?.timezone);
  }

  getDisplayTimeWindow(timeWindow: TimeWindow | undefined, timezone) {
    let dateWindow = '';
    if (timeWindow) {
      dateWindow = DateUtil.displayTimeWindow(timeWindow, {
        timezone: timezone,
        formatDateOnly: 'MMM D, YYYY',
        format: "MMM D, YYYY HH:mm",
      })
    }
    const tz = DateUtil.timezoneStandardToUsShort(timezone);
    const tzText = tz ? ` (${tz})` : '';
    return `${dateWindow}`.trim() + tzText;
  }

  onBtnDeletePost(post, tplTitle) {
    this.modalService.create({
      nzTitle: tplTitle,
      nzContent: 'This action will also remove post from the channel. Are you sure you want to delete this post?',
      nzOkText: 'Delete',
      nzOkDanger: true,
      nzWidth: '400px',
      nzOkLoading: this.isLoading,
      nzOnOk: () => {
        this.isLoading = true;
        let url = `${Const.APIURI_THIRD_PARTY_POST}/${post?.id}`;
        this.api.DELETE(url).subscribe(
          (resp) => {
            this.getPosts();
          },
          (err) => {
            this.showErr(err);
            this.isLoading = false;
          }
        )
      },
    });
  }

  isRefreshingPostId(id) {
    return this.isPostRefreshing && this.refreshPostId === id;
  }

  onBtnRefreshPost(post, idx?) {
    this.isPostRefreshing = true;
    this.refreshPostId = post.id;
    let url = `${Const.APIURI_THIRD_PARTY_POST}/${post?.id}/refresh`;
    this.api.POST(url).subscribe(
      (resp) => {
        this.getPosts();
        this.isPostRefreshing = false;
        this.refreshPostId = "";
        this.showSuccess(`Post ${idx+1} refreshed.`)
      },
      (err) => {
        this.showErr(err);
        this.isPostRefreshing = false;
        this.refreshPostId = "";
      }
    )
  }

  getPostLatestRefreshTime(post) {
    let refreshedTime = post?.refresh?.when;
    return DateUtil.dateToString(refreshedTime, 'HH:mm, DD MMM')
  }

  getPostCreatedTime(post) {
    let insertedTime = post?.insert?.when;
    return DateUtil.dateToString(insertedTime, 'HH:mm, DD MMM')
  }

  getPostUpdatedTime(post) {
    let updatedTime = post?.update?.when;
    return DateUtil.dateToString(updatedTime, 'MMM D, YYYY HH:mm')
  }

  getPartyLogo(post) {
    switch (post?.party) {
      case "dat":
        return "assets/img/logo_dat.png";
      case "truckstop":
        return "assets/img/truckstop_logo.png";
      case "truckerpath":
        return "assets/img/truckerpath_logo.png";
      default:
        return "";
    }
  }

  isClosedPost(post: ThirdPartyPost) {
    return !!post?.close;
  }

  isCreateError(post: ThirdPartyPost) {
    return post?.error && post?.error?.event === 'createPost'
  }

  getErrorMessage(post) {
    if (!this.isCreateError(post)) return '';
    const note = post?.error?.note;
    const messages = [];
    try {
      let _note = JSON.parse(note);
      switch (post.party) {
        case 'dat':
          typeof _note === "string" ? messages.push(_note) : messages.push(note);
          break;
        case 'truckstop':
          if (typeof _note === "string") {
            _note = JSON.parse(_note); // parse again
          }
          messages.push(_note?.statusSet?.[0]?.descriptor || '');
          messages.push(_note?.statusSet?.[0]?.message || '');
          break;
        case 'truckerpath':
          typeof _note === "string" ? messages.push(_note) : messages.push(note);
          break;
        default:
          messages.push(note);
          break;
      }
    }
    catch (e) {
      messages.push(note);
    }
    return messages.join(', ');
  }

  getPostStatus(post: ThirdPartyPost) {
    if (this.isClosedPost(post)) {
      return 'Removed';
    }
    if (this.isCreateError(post)) {
      return 'Error';
    }
    return 'Live';
  }

  getPostStatusColor(post: ThirdPartyPost) {
    if (this.isClosedPost(post)) {
      return 'gray';
    }
    if (this.isCreateError(post)) {
      return 'red';
    }
    return 'green';
  }
}
