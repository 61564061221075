import { Component } from "@angular/core";

import { BaseComponent } from "@abstract/BaseComponent";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";
import { Subscription } from 'rxjs';
import { Utils } from '@services/utils';
import { InputHelper } from '@services/input-helper';
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { EditOptionalEquipment } from "@app/admin/components/carrier-bid/edit-optional-equipment";

@Component({
  selector: "carrier-sale-equipment",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CarrierSaleEquipment extends BaseComponent {

  bid: any;
  jobHyperLink;
  displayInfo: any = {};
  private bidDetailSubscription: Subscription;
  onHold: string[] = []
  public shipments: any[] = [];
  
  constructor(private carrierSaleDetailService: CarrierSaleDetailService) {
    super();
  }

  ngOnInit() {
    this.bidDetailSubscription = this.carrierSaleDetailService.bidDetail$.subscribe(bidDetail => {
      this.bid = bidDetail;
      this.onProcessRouteInfo(bidDetail);
    });
  }

  processOnHold() {
    this.onHold = (this.shipments || []).filter(it => it.tags && it.tags.indexOf('HOLD') >= 0).map(it => it.warpId);
  }

  private getMileage(): string {
    const totalDistance = this.bid?.job?.totalDistance
    if (!totalDistance) return '';
    return (totalDistance / 1609.34).toFixed(2).toLocaleString();
  }

  private getVehicles(vehicles): any[] {
    if (!vehicles?.length) return [];
    vehicles = vehicles.filter(x => x);
    return vehicles.map(vehicle => {
      if (vehicle.options?.length) {
        return `${vehicle.name} /w ${vehicle.options.map(Utils.capitalize).join(", ")}`
      }
      return vehicle.name
    });
  }


  public getFirstCommodity() {
    if (this.bid?.job?.totalShipmentsItems?.commodity?.length) {
      return this.bid.job.totalShipmentsItems.commodity[0];
    }
    else return "";
  }

  public getAllCommodities() {
    return this.bid?.job?.totalShipmentsItems?.commodity?.join(', ');
  }

  private getTotalShipmentCostPerMile() {
    let mileage: any = this.getMileage();
    if (!mileage) return null;
    mileage = parseFloat(mileage);
    const totalShipmentCost = InputHelper.getValueMoney(this.bid?.job?.totalShipmentsCost)
    if (mileage && Utils.isNumber(totalShipmentCost)) {
      return `$${(totalShipmentCost / mileage).toFixed(2)}/mi`;
    }
    return null;
  }

  getLabelType(key) {
    switch (key) {
      case Const.CarrierBidTypes.direct: return 'Auto assign when carrier accept load (Direct)';
      case Const.CarrierBidTypes.manual: return 'Manual review (Indirect)';
      default: return 'Manual';
    }
  }


  private onProcessRouteInfo(bidDetail) {
    this.displayInfo = {
      isGhostLoad: bidDetail?.job?.type === WarpConst.JobType.ghost,
      isMarketplace: bidDetail?.job?.type === WarpConst.JobType.ghost && bidDetail.job?.source == WarpConst.JobSources.marketplace,
      clientName: bidDetail?.job?.clients?.[0]?.name || 'N/A',
      numOfClient: bidDetail?.job?.clients?.length ?? 0,
      arrClientName: bidDetail?.job?.clients?.map(it => it.name) || [],
      vehicles: this.getVehicles(bidDetail?.vehicles),
      firstCommodity: this.getFirstCommodity(),
      allCommodities: this.getAllCommodities(),
      tempRange: bidDetail?.job?.tempRange,
      numOfServiceOptions: bidDetail?.serviceOptions?.length ?? 0,
      firstServiceOptions: bidDetail?.serviceOptions?.[0]?.name ?? '',
      allServiceOptions: bidDetail?.serviceOptions?.map(it => it.name)?.join(', '),
      numOfOptionalEquipments: bidDetail?.optionalEquipments?.length ?? 0,
      firstOptionalEquipments: bidDetail?.optionalEquipments?.[0]?.label ?? '',
      allOptionalEquipments: bidDetail?.optionalEquipments?.map(it => it.label)?.join(', '),
      totalShipmentCost: bidDetail?.job?.totalShipmentsCost,
      totalShipmentCostPerMile: this.getTotalShipmentCostPerMile(),
      bidModeType: this.getLabelType(bidDetail?.type),
      warpOffer: Utils.isNumber(bidDetail?.basePrice) ? InputHelper.formatMoney2(String(bidDetail.basePrice)) : null,
    }
    this.processShipmentsOnHold(this.bid?.jobId);
  }
  

  ngOnDestroy() {
    if (this.bidDetailSubscription) {
      this.bidDetailSubscription.unsubscribe();
    }
  }

  private processShipmentsOnHold(jobId: string) {
    if (!jobId) return;
    const url = Const.APIV2(`${Const.APIURI_JOBS}/${jobId}/${Const.APIURI_SHIPMENTS}`);
    this.api.GET(url).subscribe(
      resp => {
        this.shipments = resp?.data?.list_data;
        this.processOnHold();
      }, err => {
        this.showErr(err);
      }
    );
  }

  onBtnEditOptionalEquipments() {
    DialogService.openFormDialog1(EditOptionalEquipment, {
      nzComponentParams: {
        model: {
          optionalEquipments: this.bid?.optionalEquipments || []
        },
        onSave: data => {
          let url = `${Const.APIURI_CARRIER_BIDS}/${this.bid.id}/update-optional-equipment`;
          return this.api.PUT(url, data)
        },
        updateSuccess: resp => {
          this.carrierSaleDetailService.emitGetDataBid();
        }
      },
      nzClassName: 'modal-no-padding',
    })
  }

}