import { Component } from "@angular/core";

import { BaseComponent } from "@abstract/BaseComponent";

@Component({
  selector: "[carrier-sale-bid-info]",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CarrierSaleBidInfo extends BaseComponent {

}