<div class="ware-item">
  <div class="ware-top">
    <div class="ware-status-group" nz-tooltip [nzTooltipTitle]="titleTemplate" [nzTooltipTitleContext]="{ $implicit: content }">
      <div class="ware-status {{status}}">Wave {{index}}</div>
      <ng-container *ngIf="isLine">
        <div class="ware-line {{isStatusBeforeItem}}"></div>
      </ng-container>
    </div>
    <ng-template #titleTemplate let-thing>
      <div>Summary: </div>
      <div [innerHTML]="thing"></div>
    </ng-template>
    <div class="ware-time">
      <ng-container *ngIf="time">
        {{getStatusTxt()}}: {{time}}
      </ng-container>
    </div>
    <div *ngIf="createdBy" class="wave-by">
      by {{ createdBy }}
    </div>
  </div>
  <div class="ware-bottom">
    <ng-container *ngIf="carrierCount">
      <div class="ware-carrier-count">
        <img class="truck1 right5" style="width: 16px;" /> {{carrierCount}} carriers
      </div>
    </ng-container>
    <ng-container *ngIf="sendEmailCount || sendSmsCount">
      <div class="ware-send">
        <span nz-icon nzType="check-circle" nzTheme="outline"></span> {{sendEmailCount}} {{sendEmailCount > 1 ? 'emails' : 'email'}} sent, {{sendSmsCount}} SMS sent
      </div>
    </ng-container>
  </div>
</div>