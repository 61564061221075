import { Component } from "@angular/core";

import { BaseComponent } from "@abstract/BaseComponent";
import { DialogService } from "@dialogs/dialog.service";
import { Subscription } from "rxjs";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";
import { Utils } from "@services/utils";
import { InputHelper } from "@services/input-helper";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { EditCarrierBidSettings } from "@app/admin/components/carrier-bid/edit-settings";
import { Const } from "@const/Const";

@Component({
  selector: "carrier-sale-same-lane-load",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CarrierSaleSameLaneLoad extends BaseComponent {
  bid: any;
  jobHyperLink;
  private bidDetailSubscription: Subscription;
  onHold: string[] = [];
  public basePrice: any //giá bid mà WARP offer
  
  constructor(private carrierSaleDetailService: CarrierSaleDetailService) {
    super();
  }

  ngOnInit() {
    this.bidDetailSubscription = this.carrierSaleDetailService.bidDetail$.subscribe(bidDetail => {
      this.bid = bidDetail;
      this.onProcessRouteInfo(bidDetail);
    });
  }

  public get carrierBidId() {
    return this.bid?.id
  }

  public get carrierSaleUrl() {
    return Const.routeAdminCarrierSales;
  }

  getMoneyValue(value) {
    return '$' + InputHelper._formatMoney(value, 0);
  }

  private onProcessRouteInfo(bidDetail) {
    this.basePrice = bidDetail?.basePrice //giá offer
  }
  

  ngOnDestroy() {
    if (this.bidDetailSubscription) {
      this.bidDetailSubscription.unsubscribe();
    }
  }
 
}