import { Const } from '@const/Const';
import polyline from '@mapbox/polyline';
import { Feature } from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf'

export class MapBidService {
  map
  bid
  setMap(map) {
    this.map = map
  }
  setBid(bid) {
    this.bid = bid
  }

  setupRouteOnMap(routeData) {
    let points = routeData?.line ? polyline.decode(routeData.line) : []
    let routeCoordinates = points.map(it => [it[1], it[0]]);
    const transpeninsularLine = {
      type: "Feature",
      properties: {
        stroke: "#555555",
        "stroke-width": 2,
        "stroke-opacity": 1
      },
      geometry: {
        type: "LineString",
        coordinates: routeCoordinates
      }
    };

    this.map.addSource("tp-line", {
      type: "geojson",
      data: transpeninsularLine,
      // Line metrics is required to use the 'line-progress' property
      lineMetrics: true
    });

    this.map.addLayer({
      id: "tp-line-line",
      type: "line",
      source: "tp-line",
      paint: {
        "line-color": "rgba(0,0,0,0)",
        "line-width": 4,
        "line-opacity": 1
      }
    });
    this.map.setFog({}); // Set the default atmosphere style

    let startTime;
    const duration = 3000;

    const frame = (time) => {
      if (!startTime) startTime = time;
      const animationPhase = (time - startTime) / duration;
      // Reduce the visible length of the line by using a line-gradient to cutoff the line
      // animationPhase is a value between 0 and 1 that reprents the progress of the animation
      this.map.setPaintProperty("tp-line-line", "line-gradient", [
        "step",
        ["line-progress"],
        Const.MAP_ROUTE_LINE_COLOR,
        animationPhase,
        "rgba(0, 0, 0, 0)"
      ]);

      if (animationPhase > 1) {
        return;
      }
      window.requestAnimationFrame(frame);
    }
    window.requestAnimationFrame(frame);
    setInterval(() => {
      startTime = undefined;
      window.requestAnimationFrame(frame);
    }, duration + 2000);
  }


  addPickupCircle(distance = 100) {
    this.map.addSource("pickup-location", {
      "type": "geojson",
      "data": {
        "type": "FeatureCollection",
        "features": [this.getPickupCircle(distance)]
      }
    });
    this.map.addLayer({
      "id": "pickup-location",
      "type": "fill",
      "source": "pickup-location",
      "layout": {},
      "paint": {
        "fill-color": "blue",
        "fill-opacity": 0.2
      }
    });
  }

  addDropCircle(distance = 100) {
    this.map.addSource("drop-location", {
      "type": "geojson",
      "data": {
        "type": "FeatureCollection",
        "features": [this.getDropCircle(distance)]
      }
    });
    this.map.addLayer({
      "id": "drop-location",
      "type": "fill",
      "source": "drop-location",
      "layout": {},
      "paint": {
        "fill-color": "red",
        "fill-opacity": 0.2
      }
    });
  }

  addStopMark(longitude, latitude, className) {
    const el = document.createElement('div');
    el.innerHTML = `<div class='stop-icon ${className}'></div>`;
    let marker = new mapboxgl.Marker(el).setLngLat([longitude, latitude]);
    marker.addTo(this.map);
  }

  addPickupMark() {
    this.addStopMark(this.pickupLocation.longitude, this.pickupLocation.latitude, 'pickup-stop')
  }

  addDropMark() {
    this.addStopMark(this.dropLocation.longitude, this.dropLocation.latitude, 'drop-stop')
  }

  addPickup() {
    this.addPickupCircle()
    this.addPickupMark()
  }

  addDrop() {
    this.addDropCircle()
    this.addDropMark()
  }

  get dropLocation() {
    return {
      latitude: this.bid?.dropoffAddress?.metadata?.latitude,
      longitude: this.bid?.dropoffAddress?.metadata?.longitude
    }
  }

  get pickupLocation() {
    return {
      latitude: this.bid?.pickupAddress?.metadata?.latitude,
      longitude: this.bid?.pickupAddress?.metadata?.longitude
    }
  }

  addStops() {
    this.addPickup()
    this.addDrop()
  }

  createGeoJSONCircle = function (center, radiusInKm, points = 64) {

    return {
      "type": "geojson",
      "data": {
        "type": "FeatureCollection",
        "features": [this.getPickupCircle()]
      }
    };
  };

  get center(): [number, number] {
    return [this.pickupLocation.longitude, this.pickupLocation.latitude];  // Los Angeles, CA
  }

  get bounds(): [[number, number], [number, number]] {
    return [[this.pickupLocation.longitude, this.pickupLocation.latitude], [this.dropLocation.longitude, this.dropLocation.latitude]]
  }

  addMatchAreasSource(areas: Feature[] = [], matchedSourceId = 'matchedSource') {
    const data = {
      type: 'FeatureCollection',
      features: areas,
    };
    this.map.addSource(matchedSourceId, { type: 'geojson', data });
    this.map.addLayer({
      'id': matchedSourceId,
      'type': 'fill',
      'source': matchedSourceId,
      'layout': {},
      'paint': {
        'fill-color': [
          'case',
          ['boolean', ['feature-state', 'click'], false],
          'rgba(218, 153, 22, 0.2)',
          'rgba(24, 2, 208, 0.2)',
        ],
        "fill-opacity": 0.2
      }
    });
    // this.map.addLayer({
    //   'id': matchedSourceId + '-border',
    //   'type': 'line',
    //   'source': matchedSourceId,
    //   'layout': {},
    //   'paint': {
    //     'line-color': [
    //       'case',
    //       ['boolean', ['feature-state', 'click'], false],
    //       'rgba(218, 153, 22, 1)',
    //       '#1802D0',
    //     ],
    //     'line-width': 0.5
    //   }
    // });
    this.map.on('click', matchedSourceId, this.onClickMatchedAreas);
  }

  setMatchedAreas(areas, matchedSourceId = 'matchedSource') {
    this.map.getSource(matchedSourceId).setData(areas);
  }

  onClickMatchedAreas(e) {

  }

  //hiện thị border khi click carrier tag
  addBorderSource(areas: Feature[] = [], matchedBorderSourceId = 'matchedBorderSource') {
    const data = {
      type: 'FeatureCollection',
      features: areas,
    };

    this.map.addSource(matchedBorderSourceId, { type: 'geojson', data });
    this.map.addLayer({
      'id': matchedBorderSourceId + '-border',
      'type': 'line',
      'source': matchedBorderSourceId,
      'layout': {},
      'paint': {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'click'], false],
          'rgba(218, 153, 22, 1)',
          '#1802D0',
        ],
        'line-width': 0.5
      }
    });
    this.map.on('click', matchedBorderSourceId, this.onClickMatchedAreas);
  }

  getPickupCircle(miles = 80) {
    return turf.circle([this.pickupLocation.longitude, this.pickupLocation.latitude], miles, { units: 'miles' })
  }
  getDropCircle(miles = 80) {
    return turf.circle([this.dropLocation.longitude, this.dropLocation.latitude], miles, { units: 'miles' })
  }
  
  bidAreas(miles = 80){
    return [
      {data: this.getPickupCircle()},
      {data: this.getDropCircle()}
    ]
  }
}