<div class="text-header top10">Market Rate</div>
<div nz-row class="line-item">
  <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">DAT Rate</div>
  <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">
    <div *ngIf="!bid?.job?.datRate?.total_charge?.mediumTotal && !bid?.job?.datRate?.cost_update?.cost">-
    </div>
    <ng-container *ngIf="bid?.job?.datRate?.total_charge?.mediumTotal">
      <ng-container *ngIf="!isLoadingDatRate">
        <span>{{getMoneyValue(bid?.job?.datRate?.cost_update?.cost ||
          bid?.job?.datRate?.total_charge?.mediumTotal)}}</span>
        <span (click)="onBtnOnUpdateTargetRate()" nz-icon nzTheme="outline" nzType="edit"
          style="color: #1802d0; cursor: pointer; margin-left: 7px;"></span>
        <span (click)="onReUpdateDatRate($event)" nz-icon nzTheme="outline" nzType="reload"
          style="color: #1802d0; cursor: pointer; margin-left: 7px;"></span>
      </ng-container>
      <ng-container *ngIf="isLoadingDatRate">
        <span nz-icon nzTheme="outline" nzType="loading"
          style="color: #1802d0; cursor: pointer; margin-left: 7px;"></span>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="bid?.job?.datRate?.cost_update?.cost > 0">
      <div style="color: #adadad; margin-top: 4px;" nzTooltipTitle="DAT Rate Original" nz-tooltip
        nzTooltipPlacement="left">
        {{getMoneyValue(bid?.job?.datRate?.total_charge?.mediumTotal)}}
      </div>
    </ng-container>
  </div>
</div>
<div nz-row class="line-item">
  <div nz-col nzSm="12" nzLg="12" nzXl="10" nzXXl="8" class="label">Historic Rate</div>
  <div nz-col nzSm="12" nzLg="12" nzXl="14" nzXXl="16">
    <ng-container *ngIf="!isLoadingAvg">
      <ng-container *ngIf="!avgCostOfRoutes">N/A</ng-container>
      <ng-container *ngIf="avgCostOfRoutes">
        {{ getMoneyValue(avgCostOfRoutes) }}
      </ng-container>
      <span (click)="onReUpdateAvgPastRate($event)" nz-icon nzTheme="outline" nzType="reload" class="reload"></span>
    </ng-container>

    <ng-container *ngIf="isLoadingAvg">
      <span nz-icon nzTheme="outline" nzType="loading" class="reload"></span>
    </ng-container>

    <a (click)="onBtnPreviousRoute()" class="btn-detail">Details</a>
  </div>
</div>