<div class="dashboard-child-container no-padding list">
  <div class="flex">
    <div class="f20 bottom20">Add carrier pools:</div>
    <div nz-col [nzSpan]="16" style="margin-left: 10px !important; margin-top: 5px;">
      <div nz-row [nzGutter]="16">
        <div nz-col>
          <b>{{getDetailLocation(model?.pickupAddress)}}</b>
        </div>
        <div nz-col><span nz-icon nzType="arrow-right" nzTheme="outline"></span></div>
        <div nz-col>
          <b>{{getDetailLocation(model?.dropoffAddress)}}</b>
        </div>
      </div>
    </div>
  </div>
  <div class="list-header">
    <div carrier-sale-pool-filter (onFilter)="doSearch($event)"></div>
  </div>
  <div class="list-body">
    <nz-table
      #nzTable
      nzBordered="true"
      [nzData]="listData"
      nzSize="small"
      [nzTotal]="totalCount"
      [nzFrontPagination]="false"
      [nzShowPagination]="totalCount > listData.length"
      [nzPageSize]="limit"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)"
    >
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th [nzChecked]="checkedAll" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"></th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="name">Pool Name</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="description">Description</th>
          <th nzColumnKey="count">Number of Carrier</th>
          <th nzColumnKey="count">Pool Type</th>
          <th nzColumnKey="count">Equipments</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listData; let i = index">
          <td
            [nzChecked]="setOfCheckedId.has(item.id)"
            [nzDisabled]="item.disabled"
            (nzCheckedChange)="onItemChecked(item, $event)"
          ></td>
          <td>{{item.name || 'N/A'}}</td>
          <td>{{item.description}}</td>
          <td>{{item.subjectIds?.length}}</td>
          <td>
            <nz-tag *ngIf="item.autoAddCarrier" nzColor="processing">Auto</nz-tag>
            <nz-tag *ngIf="item.isDedicatedPool" nzColor="success">Dedicated</nz-tag>
            <nz-tag *ngIf="!item.autoAddCarrier && !item.isDedicatedPool" nzColor="default">Normal</nz-tag>
          </td>
          <td>{{getEquipments(item)}}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
