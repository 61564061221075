<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<form *ngIf="!isLoading" class="form-detail" [formGroup]="formInput" nz-form>
  <div class="carrier-selection">
    <div class="flex">
      <div class="f20 bottom20 flex1">Assign Carrier</div>
      <button *ngIf="!isCreateNew" nz-button nzDanger (click)="onBtnUnassign()">Unassign</button>
    </div>

    <div class="tdm-row" style="margin-left: 2px">
      <div class="tdm-row" *ngIf="isAssignedCarrier">
        <div>
          <div class="form-label-v2">Current assigned</div>
          <div class="info-carrier" style="background: #d8dde1">
            <div class="content" style="padding:15px; margin-bottom: 20px">
              <span class="medium">${{ getInfoAssignedCarrier.cost}} - {{ getInfoAssignedCarrier.name }}</span>
              <br>
              <span class="medium qty-info"> assigned by {{ getInfoAssignedCarrier.assignBy }}
                at {{ getInfoAssignedCarrier.assignAt }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tdm-row cell-spacing">
      <div *ngFor="let key of ['carrierId']" class="tdm-col tdm-col-2" >
        <div class="form-label-v2 medium">Carrier</div>
        <div class="top5">{{ currentCarrierName }}</div>
        <div *ngIf="currentCarrierMcNumber">MC: {{ currentCarrierMcNumber }}</div>
        <div *ngIf="currentCarrierDotNumber">DOT: {{ currentCarrierDotNumber }}</div>
      </div>
      <div class="tdm-col tdm-col-2" >
        <div class="form-label-v2 medium">Equipment</div>
        <div class="equipment-info">{{equipmentOrderText}}</div>
      </div>
    </div>

    <div class="carrier-document-not-verified" *ngIf="currentCarrier && !currentCarrierIsVerifiedDocuments">
      <span nz-icon nzTheme="fill" nzType="info-circle" style="color: #faad14;margin-top: 3px"></span>
      <div style="margin-left: 8px; line-height: 1.5;">
        <div><b>Carrier’s Documents are not verified </b></div>
        <div>Some required documents are missing or expired. Please ensure their documents are reviewed and approved before assigning this carrier to the route.</div>
        <div style="margin-top: 10px;">
          <button nz-button (click)="onBtnGoToCarrierDetail()">Check Carrier profile</button>
        </div>
      </div>
    </div>
    
    <div *ngIf="listOfJobSameDayAndEquipment.length" class="top10">
      <div class="warning-carrier-same-route">This carrier is being assigned to {{ listOfJobSameDayAndEquipment.length }} other same-day and same-equipment {{ listOfJobSameDayAndEquipment.length > 1 ? 'routes' : 'route' }}!</div>
      <div class="flex" style="gap: 20px; flex-wrap: wrap;">
        <div *ngFor="let item of listOfJobSameDayAndEquipment">
          <a [href]="getRouterLinkByJobId(item.id)" target="_blank">{{ item.code }}</a>
        </div>
      </div>
    </div>
    <div class="tdm-row cell-spacing" style="margin-bottom: 10px; margin-top: 10px;">
      <div class="tdm-col tdm-col-2 route-job-carrier-app">
        <a *ngIf="carrierUrl" [href]="carrierUrl" target="_blank">Route link for Carrier</a>
        <a style="font-size: 19px; margin-left: 10px;" (click)="copyCarrierUrl()" nz-tooltip
          nzTooltipTitle="Copy link for carrier">
          <i nz-icon nzType="link" nzTheme="outline"></i>
        </a>
      </div>
    </div>
    <div class="tdm-row cell-spacing">
      <div class="tdm-col tdm-col-2">
        <div class="qty-info">Total Quantity: {{totalQty}}</div>
      </div>

    </div>
  </div>

  <div class="section-cost">
    <div form-input-cost #costForm [form]="formInput.get('cost')" [showSubTotal]="false" [showVolumeDiscount]="false" [origin]="'assignCarrierForm'" [totalShipmentCost]="totalShipmentCost"></div>
  </div>

</form>

<div form-footer [onProgress]="onProgress"
  [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress && !isLoading"
  [canClickCancel]="!onProgress" [labelOK]="btnSubmitLabel"
  (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>
