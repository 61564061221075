<div *ngIf="acceptedPrice">
  <div class="accepted-price flex">
    <div class="price-group">
      <div>Accepted Price:</div>
      <div class="price">{{formatMoney(acceptedPrice)}}</div>
      
    </div>
    <div class="right-group">
      <div class="carrier-name">{{acceptedCarrier?.basicInfo?.name}}</div>
      <div class="flex">
        <div *ngIf="!isLoading" style="margin-bottom: 10px;">
          <span><nz-tag *ngIf="isEarliestBid" [nzColor]="'blue'">Earliest Bid</nz-tag></span>
          <span><nz-tag *ngIf="isLowestPrice" [nzColor]="'blue'">Lowest Price</nz-tag></span>
          <span><nz-tag *ngIf="isBestExperience" [nzColor]="'blue'">Best Experience</nz-tag></span>
        </div>
        <ng-container *ngIf="compareWithLowestPrice" [ngTemplateOutlet]="statisticTemplate"
          [ngTemplateOutletContext]="{data: compareWithLowestPrice}">
        </ng-container>
        <ng-container *ngIf="compareWithOfferPrice" [ngTemplateOutlet]="statisticTemplate"
          [ngTemplateOutletContext]="{data: compareWithOfferPrice}">
        </ng-container>

        <ng-template #statisticTemplate let-data='data'>
          <div style="margin-bottom: 10px;">
            <nz-tag [nzColor]="getCompareBidPriceColor(data?.status)">
              <span nz-icon [nzType]="getCompareBidPriceIcon(data?.status)" nzTheme="outline"></span>
              <span style="margin-right: 5px; margin-left: 2px;" *ngIf="data?.percent">{{data?.percent}}</span>
              <span>{{data?.text}}</span>
            </nz-tag>
          </div>
        </ng-template>
      </div>
      <!-- bug: wrong market price => wrong compare (FIX ME) -->
      <!-- <ng-container
        *ngIf="compareWithMarketPrice"
        [ngTemplateOutlet]="statisticTemplate"
        [ngTemplateOutletContext]="{data: compareWithMarketPrice}">
      </ng-container> -->
    </div>
  </div>
</div>