import { Component, Input } from "@angular/core";

import { BaseComponent } from "@abstract/BaseComponent";
import { InputHelper } from "@services/input-helper";
import { Const } from "@const/Const";

@Component({
  selector: "carrier-sale-accept-price",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CarrierSaleAcceptedPrice extends BaseComponent {

  @Input() bid: any; //giá bid đã chốt
  isLowestPrice: boolean = false;
  isEarliestBid: boolean = false;
  isBestExperience: boolean = false;
  isLoading: boolean = false;
  lowestPrice: number;

  ngOnInit(): void {
    super.ngOnInit();
    this.getAcceptedPriceInfo();
  }

  get acceptedCarrier() {
    return this.bid?.job?.carrier;
  } 

  get acceptedPrice() {
    return this.bid?.job?.assignedCarrier?.cost?.grandTotal;
  }

  getAcceptedPriceInfo() {
    this.isLoading = true;
    const url = `${Const.APIV2(Const.APIURI_CARRIER_BIDS)}/${this.bid?.id}/accepted-price-info`;
    this.api.GET(url).subscribe((response) => {
        this.isLoading = false;
        this.isLowestPrice = response.data.isLowestPrice;
        this.isEarliestBid = response.data.isEarliestBid;
        this.isBestExperience = response.data.isBestExperience;
        this.lowestPrice = response.data.lowestPrice;
      },
      error => {
        this.showErr(error);
        this.isLoading = false;
      }
    )
  }

  calcPercentage(x, y) {
    if (!x || !y || isNaN(x) || isNaN(y)) return
    const percent = ((x / y) * 100) - 100
    if (!percent) return
    return `${(percent).toFixed(2)}%`;
  }

  formatMoney(money: number) {
    return InputHelper.formatMoney2((money).toFixed(2).toString());
  }

  compareWithOtherPrice(text: string, price: number) {
    if (!price || isNaN(price) || !isFinite(price)) return
    if (this.acceptedPrice > price) {
      return {
        status: Const.compareBidPrice.more,
        percent: this.calcPercentage(this.acceptedPrice, price),
        text: `more than ${text} ${this.formatMoney(this.acceptedPrice - price)}`
      }
    }
    if (this.acceptedPrice == price) {
      return {
        status: Const.compareBidPrice.equal,
        percent: this.calcPercentage(this.acceptedPrice, price),
        text: `Equal to ${text} ${this.formatMoney(this.acceptedPrice)}`
      }
    }
    if (this.acceptedPrice < price) {
      return {
        status: Const.compareBidPrice.less,
        percent: this.calcPercentage(price, this.acceptedPrice),
        text: `less than ${text} ${this.formatMoney(price - this.acceptedPrice)}`
      }
    }
    return
  }

  getCompareBidPriceColor(status) {
    switch (status) {
      case Const.compareBidPrice.more:
        return 'red'
      case Const.compareBidPrice.equal:
      case Const.compareBidPrice.less:
        return 'green'
    }
  }

  getCompareBidPriceIcon(status) {
    switch (status) {
      case Const.compareBidPrice.more:
        return 'arrow-up'
      case Const.compareBidPrice.equal:
        return 'check'
      case Const.compareBidPrice.less:
        return 'arrow-down'
    }
  }


  get compareWithLowestPrice() {
    return this.compareWithOtherPrice('lowest price', this.lowestPrice)
  }

  get compareWithOfferPrice() {
    return this.compareWithOtherPrice('offer price', this.bid?.basePrice);
  }
 
}