import { Component } from "@angular/core";

import { BaseComponent } from "@abstract/BaseComponent";
import { DialogService } from "@dialogs/dialog.service";
import { Subscription } from "rxjs";
import { CarrierSaleDetailService } from "@app/admin/carrier-sale-detail/carrier-sale-detail.service";
import { Utils } from "@services/utils";
import { InputHelper } from "@services/input-helper";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { EditCarrierBidSettings } from "@app/admin/components/carrier-bid/edit-settings";
import { Const } from "@const/Const";
import { AddTargetRate } from "@app/admin/carrier-sales-v2/target-rate";
import { ViewPreviousRouteComponent } from "@app/admin/components/carrier-bid/view-previous-route/view-previous-route.component";

@Component({
  selector: "carrier-sale-market-rate",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CarrierSaleMarketRate extends BaseComponent {
  bid: any;
  displayInfo: any = {};
  private bidDetailSubscription: Subscription;
  public avgCostOfRoutes: any //giá bid trung bình của route này
  isLoadingAvg = false;
  isLoadingDatRate = false;
  
  constructor(private carrierSaleDetailService: CarrierSaleDetailService) {
    super();
  }

  ngOnInit() {
    this.bidDetailSubscription = this.carrierSaleDetailService.bidDetail$.subscribe(bidDetail => {
      this.bid = bidDetail;
      this.onProcessRouteInfo(bidDetail);
    });
  }
  getLabelType(key) {
    switch (key) {
      case Const.CarrierBidTypes.direct: return 'Auto assign when carrier accept load (Direct)';
      case Const.CarrierBidTypes.manual: return 'Manual review (Indirect)';
      default: return 'Manual';
    }
  }

  private onProcessRouteInfo(bidDetail) {
    this.avgCostOfRoutes = bidDetail?.job?.avgPastRates;
    // this.displayInfo = {
    //   isGhostLoad: bidDetail?.job?.type === WarpConst.JobType.ghost,
    //   isMarketplace: bidDetail?.job?.type === WarpConst.JobType.ghost && bidDetail.job?.source == WarpConst.JobSources.marketplace,
    //   clientName: bidDetail?.job?.clients?.[0]?.name || 'N/A',
    //   numOfClient: bidDetail?.job?.clients?.length ?? 0,
    //   arrClientName: bidDetail?.job?.clients?.map(it => it.name) || [],
    //   tempRange: bidDetail?.job?.tempRange,
    //   numOfServiceOptions: bidDetail?.serviceOptions?.length ?? 0,
    //   firstServiceOptions: bidDetail?.serviceOptions?.[0]?.name ?? '',
    //   allServiceOptions: bidDetail?.serviceOptions?.map(it => it.name)?.join(', '),
    //   numOfOptionalEquipments: bidDetail?.optionalEquipments?.length ?? 0,
    //   firstOptionalEquipments: bidDetail?.optionalEquipments?.[0]?.label ?? '',
    //   allOptionalEquipments: bidDetail?.optionalEquipments?.map(it => it.label)?.join(', '),
    //   totalShipmentCost: bidDetail?.job?.totalShipmentsCost,
    //   bidModeType: this.getLabelType(bidDetail?.type),
    //   warpOffer: Utils.isNumber(bidDetail?.basePrice) ? InputHelper.formatMoney2(String(bidDetail.basePrice)) : null,
    // }
  }

  onBtnPreviousRoute() {
    DialogService.openFormDialog1(ViewPreviousRouteComponent, {
      nzComponentParams: {
        model: this.bid,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.carrierSaleDetailService.emitGetDataBid();
        },
      },
      nzClassName: "view-previous-route-form modal-xl",
    });
  }

  public onBtnOnUpdateTargetRate() {
    DialogService.openFormDialog1(AddTargetRate, {
      nzComponentParams: {
        data: this.bid?.job?.datRate?.cost_update?.cost,
        closeOnSuccess: true,
        onSave: data => this.updateDatRate(data)
      },
    });
  }

  onReUpdateDatRate(event) {
    this.updateDatRate({});
  }

  private updateDatRate(params) {
    if (!this.bid?.jobId) return;
    this.isLoadingDatRate = true;
    this.api.PUT(`${Const.APIURI_JOBS}/${this.bid.jobId}/update_dat_rate_for_job`, params).subscribe(
      resp => {
        if (resp?.data?.total_charge && this.bid?.job) {
          this.bid.job.datRate = resp?.data;
        }
        this.isLoadingDatRate = false;
      }, err => {
        this.isLoadingDatRate = false;
      }
    );
  }

  onReUpdateAvgPastRate(event) {
    this.updateAvgPastRate({});
  }

  private updateAvgPastRate(params) {
    if (!this.bid?.jobId) return;
    this.isLoadingAvg = true;
    this.api.POST(Const.APIV2(`${Const.APIURI_JOBS}/${this.bid.jobId}/update_avg_past_rate`), params).subscribe(
      resp => {
        if (resp?.data && this.bid?.job) {
          this.avgCostOfRoutes = resp?.data?.avgPastRates;
        }
        this.isLoadingAvg = false;
      }, err => {
        this.isLoadingAvg = false;
      }
    );
  }

  onBtnEditSettings() {
    DialogService.openFormDialog1(EditCarrierBidSettings, {
      nzComponentParams: {
        model: {
          type: this.bid?.type,
          isAllowPlaceBid: this.bid?.isAllowPlaceBid,
          isShowBasePrice: this.bid?.isShowBasePrice,
          basePrice: this.bid?.basePrice,
          isShowOnBidBoard: this.bid?.isShowOnBidBoard,
          isRequireCarrierAcceptLoadTender: this.bid?.isRequireCarrierAcceptLoadTender,
        },
        isSourceMarketPlace: this.displayInfo.isMarketplace,
        onSave: data => {
          let url = `${Const.APIURI_CARRIER_BIDS}/${this.bid.id}/settings`;
          return this.api.PUT(url, data)
        },
        updateSuccess: resp => {
          this.carrierSaleDetailService.emitGetDataBid();
        }
      },
      nzClassName: 'modal-no-padding',
    })
  }
  
  getMoneyValue(value) {
    return '$' + InputHelper._formatMoney(value, 0);
  }

  ngOnDestroy() {
    if (this.bidDetailSubscription) {
      this.bidDetailSubscription.unsubscribe();
    }
  }
 
}