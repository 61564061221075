<carrier-sale-pools-select-list #tablePools [model]="model"></carrier-sale-pools-select-list>

<div
  form-footer
  [onProgress]="onProgress"
  [nzIconCancel]="null"
  [nzIconOK]="null"
  [canClickOK]="canClickOK() && !onProgress"
  [canClickCancel]="!onProgress"
  labelOK="Add"
  (onOK)="updateCarrier()"
  (onCancel)="closeDialog()"
></div>