import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import _ from 'underscore';
import { getDashboard } from "@services/index";
import { BaseDetail } from "@app/admin/base/detail";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { CarrierSaleDetailService } from "../carrier-sale-detail.service";
import { Title } from "@angular/platform-browser";
const API_URLS = {
  GET_CARRIER_BIDS: Const.APIURI_CARRIER_BIDS,
};
const CHECKSTATUS = {
  NO_ITEM_CHECKED: 0,
  AT_LEAST_ONE_ITEM_CHECKED: 1,
  ALL_ITEMS_CHECKED: 2
}
@Component({
  selector: "carrier-sale-detail",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CarrierSaleDetailComponent extends BaseDetail {

  public isError = false;
  public isLoading = false;
  public data: any = {};
  public isShowAddCarrierModal = false
  public arrBidFromPublic: any[] = [];
  protected get crudEntity(): string { return 'carrier_bids' }
  public keywordSearchName: string;
  public keywordSearchPoolName: string;
  public keywordSearchContacts: string;
  public listData: any = [];
  public listPool: any = [];
  public shipments: any[] = [];
  public sortPriceDirection = 'ascend';

  protected subscribeTopbarTitle(fn: Function) {
    return fn("View Carrier Bid");
  }

  public listCarrierWithGroup: any[];     // mảng sau khi đã group theo dedicated pool

  public avgCostOfRoutes: any //giá bid trung bình của route này
  public earliestBid: any //thời gian phản hồi bid sớm nhất
  public lowestPrice: any //giá bid thấp nhất
  public bestExperience: any //chạy tuyến hiện tại nhiều nhất
  public basePrice: any //giá bid mà WARP offer
  public acceptedPrice: any //giá bid đã chốt
  public acceptedCarrier: any
  public displayInfo: any = {};
  public get canPerformAction(): boolean { return true }

  public get checkedStatus() {
    const carriers = this.data.carriers || [];
    let countChecked = carriers.filter(carrier => carrier.checked).length
    if (countChecked == 0) return CHECKSTATUS.NO_ITEM_CHECKED;                   // No item is checked
    if (countChecked == carriers.length) return CHECKSTATUS.ALL_ITEMS_CHECKED;     // All items are checked
    return CHECKSTATUS.AT_LEAST_ONE_ITEM_CHECKED;                                         // A few items (not all) are checked
  }

  shouldShowBtnDat = false;
  datPostExist = false;

  public tabSelected: number = 0;

  private getDataSubscription: Subscription;

  constructor(
    private titleService: Title,
    private carrierSaleDetailService: CarrierSaleDetailService,
    activatedRoute: ActivatedRoute,
    api: ApiService,
  ) {
    super(activatedRoute);
    this.api = api;
    this.activatedRoute.params.subscribe(params => {
      super.handleNavigationEnd(activatedRoute.toString(), activatedRoute.queryParamMap);
    })
  }

  public jobHyperLink;

  public get carrierBidId() {
    return this.id
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.getDataSubscription = this.carrierSaleDetailService.getDataObservable().subscribe(() => {
      this.getData();
    });
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
  }

  ngOnDestroy(): void {
    if (this.getDataSubscription) {
      this.getDataSubscription.unsubscribe();
    }
  }


  getData() {
    if (!this.carrierBidId) return;
    this.isLoading = true;

    let urls = [
      `${API_URLS.GET_CARRIER_BIDS}/${this.carrierBidId}`,
    ];
    this.api.concurrentGET(urls).subscribe(
      resp => {
        const bid = resp[0].data;
        this.titleService.setTitle(`${bid?.job?.code} - Carrier BID - WARP Admin`);
        this.carrierSaleDetailService.setBidDetail(bid);
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        this.showErr(err);
        this.isError = true;
      }
    );
  }
}
