<div class="carrier-selection">
  <div>
    <div class="text-header">Outreach</div>
    <div class="box-content top20">
      <div class="carrier-tab">
        <nz-tabset [(nzSelectedIndex)]="tabSelected" [nzType]="'line'" [nzSize]="'small'"
          (nzSelectedIndexChange)="onTabSelectedIndexChange()"
        >
          <nz-tab nzTitle="Invite Bid Automation"></nz-tab>
          <nz-tab nzTitle="External Posting"></nz-tab>
        </nz-tabset>
      </div>
      <ng-container *ngIf="isMatchedCarriersTab">
        <div>
          <carrier-sale-wave></carrier-sale-wave>
        </div>
      </ng-container>
      
      <ng-container *ngIf="isExternalPostingTab">
        <div>
          <list-external-posting></list-external-posting>
        </div>
      </ng-container>
     
    </div>
  </div>
</div>
